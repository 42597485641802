<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS COMUNIDADES</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-people-carry fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaComunidades.length}}</span>
                            <br>
                            <span class="text-muted">Comunidades registradas</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de comunidades</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  md="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Informe a la comunidad'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Regresar
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('016-VCM-INC-COM','c')==1" md="6" class="my-2">
                            <b-button block size="md" @click="modalRegistrarComunidad = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nueva comunidad
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Comunidades registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaComunidades" :fields="campoComunidad" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('016-VCM-INC-COM','u')==1" @click="abrirModalDetalleComunidad(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('016-VCM-INC-COM','d')==1" @click="eliminarComunidades(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalRegistrarComunidad">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva comunidad </span>
            </h6>
            <CButtonClose @click="modalRegistrarComunidad = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarComunidad)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.nombre" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="lugar" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Lugar:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.lugar" placeholder="Ingrese el lugar"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.ubicacion" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="cuidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cuidad:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.cuidad" placeholder="Ingrese la ciudad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cantidad de personas" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Cantidad de personas:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.cantidadPersonas" placeholder="Ingrese la cantidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="representante principal" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Representante principal:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.representantePrincipal" placeholder="Ingrese el representante"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="representante" v-slot="validationContext">
                            <b-form-group label="Representante:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosComunidad.representante" placeholder="Ingrese el representante"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12">
                        <validation-provider name="detalles" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea v-model="datosComunidad.detalles" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarComunidad = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalActualizarComunidad">

        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar comunidad</span></h6>
            <CButtonClose @click="modalActualizarComunidad = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarComunidad)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.nombre" placeholder="Ingrese el nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="lugar" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Lugar:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.lugar" placeholder="Ingrese el lugar"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.ubicacion" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="cuidad" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cuidad:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.cuidad" placeholder="Ingrese la ciudad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="cantidad de personas" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Cantidad de personas:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.cantidadPersonas" placeholder="Ingrese la cantidad"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="representante principal" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Representante principal:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.representantePrincipal" placeholder="Ingrese el representante"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="representante" v-slot="validationContext">
                            <b-form-group label="Representante:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarComunidad.representante" placeholder="Ingrese el representante"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="detalles" v-slot="validationContext">
                            <b-form-group label="Detalles:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarComunidad.detalles" :state="getValidationState(validationContext)" placeholder="Ingrese los detalles" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarComunidad = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,

            modalRegistrarComunidad: false,
            modalActualizarComunidad: false,
            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoComunidad: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Comunidad",
                    class: "text-center",
                }, , {
                    key: "ubicacion",
                    label: "Ubicación",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaComunidades: [],
            datosComunidad: {
                idCliente: '',
                nombre: '',
                ubicacion: '',
                lugar: '',
                cuidad: '',
                representantePrincipal: '',
                representante: '',
                cantidadPersonas: '',
                detalles: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosActualizarComunidad: {
                idComunidad: null,
                idCliente: '',
                nombre: '',
                ubicacion: '',
                lugar: '',
                cuidad: '',
                representantePrincipal: '',
                representante: '',
                cantidadPersonas: '',
                detalles: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        abrirModalDetalleComunidad(param) {
            let me = this;

            this.datosActualizarComunidad.idComunidad = param.item.idComunidad;
            this.datosActualizarComunidad.nombre = param.item.nombre;
            this.datosActualizarComunidad.ubicacion = param.item.ubicacion;
            this.datosActualizarComunidad.lugar = param.item.lugar;
            this.datosActualizarComunidad.cuidad = param.item.cuidad;
            this.datosActualizarComunidad.representantePrincipal = param.item.representantePrincipal;
            this.datosActualizarComunidad.representante = param.item.representante;
            this.datosActualizarComunidad.cantidadPersonas = param.item.cantidadPersonas;
            this.datosActualizarComunidad.detalles = param.item.detalles;
            this.datosActualizarComunidad.ejercicio = param.item.ejercicio;

            me.modalActualizarComunidad = true;
        },
        listarComunidades() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-comunidades", {
                        params: {
                            idCliente: me.datosComunidad.idCliente,
                            ejercicio: me.datosComunidad.ejercicio
                        },
                    }
                )
                .then(function (response) {
                    me.listaComunidades = response.data;
                    me.totalRows = me.listaComunidades.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarComunidad() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-comunidad",
                    me.datosComunidad, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarComunidad = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarComunidades();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });

        },
        actualizarComunidad() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-comunidad",
                    me.datosActualizarComunidad, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarComunidad = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarComunidades();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalRegistrarComunidad() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosComunidad.nombre = '';
            this.datosComunidad.ubicacion = '';
            this.datosComunidad.lugar = '';
            this.datosComunidad.cuidad = '';
            this.datosComunidad.representantePrincipal = '';
            this.datosComunidad.representante = '';
            this.datosComunidad.cantidadPersonas = '';
            this.datosComunidad.detalles = '';

        },
        resetModalActualizarComunidad() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },

        eliminarComunidades(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la comunidad?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-comunidad", {
                                idComunidad: param.item.idComunidad
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarComunidades();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },

    watch: {
        modalRegistrarComunidad: function (val) {
            if (val == false) {
                this.resetModalRegistrarComunidad();
            }
        },
        modalActualizarComunidad: function (val) {
            if (val == false) {
                this.resetModalActualizarComunidad();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosComunidad.idCliente = this.datosActualizarComunidad.idCliente = user.uidClient;
            this.listarComunidades();
        }
    }

}
</script>
